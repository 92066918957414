document.addEventListener('DOMContentLoaded', () => {
    const container = document.querySelector('.HerramientasContenedor__inner');
    const images = document.querySelectorAll('.HerramientasContenedor__imagen');

    const imageWidth = parseFloat(getComputedStyle(images[0]).width);
    const gap = parseFloat(getComputedStyle(container).gap);
    const totalWidth = (imageWidth + gap) * images.length;

    const cloneImagesOnce = Array.from(images).map(img => img.cloneNode(true));
    const cloneImagesTwice = Array.from(images).map(img => img.cloneNode(true));
    
    container.append(...cloneImagesOnce, ...cloneImagesTwice);

    container.style.width = `${totalWidth * 0.3}px`;

    let offset = 0;
    const speedLargeScreen = 0.5;  // Velocidad para pantallas grandes
    const speedSmallScreen = 0.5; // Velocidad para pantallas pequeñas

    function animate() {
        // Ajustar la velocidad según el tamaño de la pantalla
        if (window.innerWidth > 1024) {
            offset += speedLargeScreen; // Incremento de offset para pantallas grandes
        } else {
            offset += speedSmallScreen; // Incremento de offset para pantallas pequeñas
        }

        if (offset >= totalWidth) {
            offset = 0;
        }
        
        container.style.transform = `translateX(-${offset}px)`;
        requestAnimationFrame(animate);
    }

    animate();
});
